export interface WebsiteConfig {
  metaPublisher: string;
  metaAuthor: string;
  title: string;
  description: string;
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  github?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: '@marpontes blog',
  description: 'Tech ideas applied at home and around',
  coverImage: 'img/blog-cover.png',
  logo: 'img/marpontes-dark-nomargins.png',
  lang: 'en',
  siteUrl: 'https://marpont.es',
  facebook: undefined,
  metaPublisher: 'https://www.facebook.com/marpontes',
  metaAuthor: 'https://www.facebook.com/marpontes',
  github: 'https://www.github.com/marpontes',
  twitter: 'https://twitter.com/marpontes',
  showSubscribe: true,
  mailchimpAction: 'https://marpont.us17.list-manage.com/subscribe/post?u=71fd69addb3a7b5218139bf45&amp;id=5c6671297f',
  mailchimpName: 'b_a89b6987ac248c81b0b7f3a0f_7d777b7d75',
  mailchimpEmailFieldName: 'MERGE0',
  googleSiteVerification: 'GoogleCode',
  footer: 'is based on Gatsby Casper',
};

export default config;
